<template>
  <div class="container-general">
    <div class="div-text m-5">
      <h1 class="text-rgs">Selecciona una opción</h1>
    </div>
    <div class="container-users">
      <router-link to="/selectstudent" class="btn-f shadow-lg p-3 mb-5 bg-body-tertiary rounded"
        style="border: 1px solid black; height: 400px;">
        <div class="img-f">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" style="fill: black; ">
            <path d="M2 7v1l11 4 9-4V7L11 4z"></path>
            <path
              d="M4 11v4.267c0 1.621 4.001 3.893 9 3.734 4-.126 6.586-1.972 7-3.467.024-.089.037-.178.037-.268V11L13 14l-5-1.667v3.213l-1-.364V12l-3-1z">
            </path>
          </svg>
        </div>
        <div class="container-targets">
          <p class="text-targets">Universitario</p>
        </div>
        <div>
          <p class="description-targets">Bachillerato o Técnica (Estudiando)</p>
        </div>
        <div>
          <p class="description-targets">Universitario (Estudiando)</p>
        </div>
        <div>
          <p class="description-targets">Profesionista (Con Experiencia)</p>
        </div>
      </router-link>
      <router-link to="/formularioempresa" class="btn-f shadow-lg p-3 mb-5 bg-body-tertiary rounded"
        style="border: 1px solid black; height: 400px;">
        <div class="img-f">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"
            style="fill: black;">
            <path
              d="M21 7h-6a1 1 0 0 0-1 1v3h-2V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zM8 6h2v2H8V6zM6 16H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V6h2v2zm4 8H8v-2h2v2zm0-4H8v-2h2v2zm9 4h-2v-2h2v2zm0-4h-2v-2h2v2z">
            </path>
          </svg>
        </div>
        <div class="container-targets">
          <p class="text-targets">Empresa</p>
        </div>
      </router-link>
      <router-link to="/formulariouniversidades" class="btn-f shadow-lg p-3 mb-5 bg-body-tertiary rounded"
        style="border: 1px solid black; height: 400px;">
        <div class="img-f">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"
            style="fill: black;">
            <path d="M21 10h-2V4h1V2H4v2h1v6H3a1 1 0 0 0-1 1v9h20v-9a1 1 0 0 0-1-1zm-7 8v-4h-4v4H7V4h10v14h-3z"></path>
            <path d="M9 6h2v2H9zm4 0h2v2h-2zm-4 4h2v2H9zm4 0h2v2h-2z"></path>
          </svg>
        </div>
        <div class=" container-targets">
          <p class="text-targets">Universidad</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style land="scss" scoped>
@import "../styles/selectuser.scss";
</style>

<script>
import { UserService } from "@/services";

export default {
  data: () => ({}),
  mounted() {
    //this.loader("play");
    this.checkedUser();
  },
  methods: {
    loader(type) {
      const loader = document.getElementById("loader-initial");
      if (type === "play") {
        loader.style.display = "flex";
      } else {
        loader.style.display = "none";
      }
    },
    async checkedUser() {
      try {
        console.log(this.$route.query)
        const { token } = this.$route.query;
        if (token) {
          localStorage.setItem("accessToken", token);
        }
        const resp = await UserService.checkedLogin();
        const user = resp.data.data;
        this.loader("pause");
        localStorage.setItem("user", JSON.stringify(user));
        if (user.register_complete) {
          const role = user.roles[0];
          if (role === "student") {
            window.location.href = "/profile";
          } else if (role === "company") {
            //window.location.href = "/profileempresa";
          } else if (role === "admin") {
            window.location.href = "/admin";
            // eslint-disable-next-line no-empty
          } else {
          }
        } else {
          if (!user.register_complete) {
            const role = user.roles[0];
            if (role === "student") {
              window.location.href = "/profile";
            } else if (role === "company") {
              window.location.href = "/profileempresa";
            } else if (role === "admin") {
              window.location.href = "/admin";
            }
            if (!user.register_complete) {
              this.$swal({
                position: "top-center",
                icon: "error",
                title: "Tu registro esta incompleto, selecciona un perfíl.",
                showConfirmButton: false,
                timer: 5000,
              });
            }
          }
        }
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
  },
};
</script>
